//
// Form text
//

.form-text {
  margin-top: -.25rem;
  margin-bottom: .5rem;
  @include font-size($form-text-font-size); // 12px
  font-style: $form-text-font-style;
  font-weight: $form-text-font-weight;
  color: $form-text-color;
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-text {
      color: #b7b9c3;
    }
  }
}
