:root,
[data-bs-theme="light"] {
  color-scheme: light;
  --bs-blue: #0063a3; // Primary / Trimble Blue
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #da212c; // Trimble Red
  --bs-orange: #fd7e14;
  --bs-yellow: #fbad26; // Trimble Yellow
  --bs-green: #1e8a44; // Trimble Green
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #585c65; // Gray 7
  --bs-gray-dark: #171c1e; // Gray 10
  --bs-gray-100: #f1f1f6; // Gray Light
  --bs-gray-200: #e0e1e9; // Gray 0
  --bs-gray-300: #cbcdd6; // Gray 1
  --bs-gray-400: #b7b9c3; // Gray 2
  --bs-gray-500: #a3a6b1; // Gray 3
  --bs-gray-600: #90939f; // Gray 4
  --bs-gray-700: #7d808d; // Gray 5
  --bs-gray-800: #464b52; // Gray 8
  --bs-gray-900: #353a40; // Gray 9
  --bs-primary: #0063a3; // Primary / Trimble Blue
  --bs-secondary: #6a6e79; // Gray 6

  --bs-success: #006638; // Green Dark
  --bs-tertiary: #b7b9c3; // Gray 2
  --bs-warning: #e49325; // Yellow Dark
  --bs-danger: #da212c; // Red
  --bs-light: #f1f1f6; // $col_gray_light
  --bs-dark: #252a2e; // Trimble Gray
  --bs-primary-rgb: 0, 99, 163;
  --bs-secondary-rgb: 106, 110, 121; // Gray 6
  --bs-tertiary-rgb: 183, 185, 195;
  --bs-success-rgb: 0, 102, 56;
  --bs-warning-rgb: 228, 147, 37;
  --bs-danger-rgb: 218, 33, 44;
  --bs-light-rgb: 240, 240, 245; // $col_gray_light
  --bs-dark-rgb: 37, 42, 46;
  --bs-primary-text-emphasis: #004f83; // Trimble Blue Dark
  --bs-secondary-text-emphasis: #6a6e79; // Gray 6
  --bs-success-text-emphasis: #006638; // Green Dark
  --bs-info-text-emphasis: #217cbb; // Blue Light
  --bs-warning-text-emphasis: #e49325; // Yellow Dark
  --bs-danger-text-emphasis: #da212c; // Trimble Red
  --bs-light-text-emphasis: #585c65; // Gray 7
  --bs-dark-text-emphasis: #171c1e; // Gray 10
  --bs-primary-bg-subtle: #e0e1e9; // Gray 0
  --bs-secondary-bg-subtle: #f1f1f6; // Gray Light
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #f1f1f6; // Gray Light
  --bs-dark-bg-subtle: #7d808d;
  --bs-primary-border-subtle: #99c1da;
  --bs-secondary-border-subtle: #cbcdd6;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #cbcdd6;
  --bs-dark-border-subtle: #585c65;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Open Sans", system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: .875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #252a2e; // $col_trimble_gray
  --bs-body-color-rgb: 37, 42, 46;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgb(106 110 121); // #6a6e79
  --bs-secondary-color-rgb: 106, 110, 121; // #6a6e79
  --bs-secondary-bg: #6a6e79; // Gray 6
  --bs-secondary-bg-rgb: 203, 205, 214;
  --bs-tertiary-color: rgba(241, 241, 246); // #cbcdd6
  --bs-tertiary-color-rgb: 241, 241, 246; // #cbcdd6
  --bs-tertiary-bg: #cbcdd6; // #cbcdd6
  --bs-tertiary-bg-rgb: 241, 241, 246; // #cbcdd6
  --bs-link-color: #0063a3; // Trimble Light Blue
  --bs-link-color-rgb: 33, 124, 187; // Trimble Light Blue
  --bs-link-decoration: none;
  --bs-link-hover-color: #16517a;
  --bs-link-hover-color-rgb: 22, 81, 122;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff5e4; // $col_yellow_pale
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #cbcdd6; // Gray 1
  --bs-border-color-translucent: rgba(0, 0, 0, .175);
  --bs-border-radius: .25rem;
  --bs-border-radius-sm: .125rem;
  --bs-border-radius-lg: .25rem;
  --bs-border-radius-xl: .5rem;
  --bs-border-radius-xxl: 1rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0 4px rgba(37, 42, 46, .3);
  --bs-box-shadow-sm: 0 0 2px rgba(37, 42, 46, .3);
  --bs-box-shadow-lg: 0 0 8px rgba(37, 42, 46, .3);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, .075);
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: rgba(13, 110, 253, .25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-form-control-border-bottom: #6a6e79; // Gray 6
  --bs-form-label-color: #464b52; // Gray 8
  --bs-form-check-input-border: #90939f; // Gray 4
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-blue: #019aeb; // Highlight Blue
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #171c1e; // Gray 10
  --bs-body-bg-rgb: 23, 28, 30;
  --bs-emphasis-color: #f1f1f6; // Gray Light
  --bs-emphasis-color-rgb: 241, 241, 246;
  --bs-primary: #019aeb; // Highlight Blue
  --bs-primary-bg: #004f83; // Trimble Dark Blue
  --bs-success: #1e8a44; // Green
  --bs-secondary-color: rgba(255, 255, 255, .75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #171c1e; // Gray 10
  --bs-secondary-bg-rgb: 27, 26, 38;
  --bs-tertiary-color: rgba(255, 255, 255, .5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #21202d;
  --bs-tertiary-bg-rgb: 33, 32, 45;
  --bs-primary-text-emphasis: #019aeb; // Highlight Blue
  --bs-secondary-text-emphasis: #6a6e79;
  --bs-success-text-emphasis: #006638;
  // --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #fbad26; // Trimble Yellow
  --bs-danger-text-emphasis: #da212c; // Danger Color
  --bs-light-text-emphasis: #f1f1f6; // Gray Light
  --bs-dark-text-emphasis: #e0e1e9; // Gray 0
  --bs-primary-bg-subtle: #0c5b85;
  --bs-secondary-bg-subtle: #464b52; // Gray 8
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #171c1e; // Gray 10
  --bs-dark-bg-subtle: #0e0d13;
  --bs-primary-border-subtle: #003b62;
  --bs-secondary-border-subtle: #363545;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #055160;
  --bs-warning-border-subtle: #664d03;
  --bs-danger-border-subtle: #ab1f26; // Red Dark
  --bs-light-border-subtle: #363545;
  --bs-dark-border-subtle: #171c1e; // Gray 10
  --bs-heading-color: #fff;
  --bs-link-color: #66a1c8;
  --bs-link-hover-color: #99c1da;
  --bs-link-color-rgb: 1, 154, 235; // Dark Mode Blue
  --bs-link-hover-color-rgb: 153, 193, 218;
  --bs-code-color: #e685b5;
  --bs-border-color: #353a40;
  --bs-border-color-translucent: rgba(255, 255, 255, .15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
  --bs-form-label-color: #b7b9c3;
  --bs-form-control-border-bottom: #6a6e79;
  --bs-btn-bg: #019aeb; // Highlight Blue

  --bs-alert-link-color: #f1f1f6;

  --bs-modal-bg: #252a2e; // Trimble Gray
  --bs-modal-header-border-color: #6a6e79;
  --bs-modal-footer-border-color: #6a6e79;

  --bs-nav-link-color: #f1f1f6;
  --bs-pagination-border-radius: 4px;
  --bs-list-group-color: #f1f1f6;

  --bs-tooltip-bg: #e0e1e9;
  --bs-tooltip-color: #353a40;

  --bs-primary-rgb: 1, 154, 235;
}
