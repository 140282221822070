//
// Rotating border
//

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--#{$prefix}spinner-width);
  height: var(--#{$prefix}spinner-height);
  color: var(--#{$prefix}blue); // Modus customized - blue
  vertical-align: var(--#{$prefix}spinner-vertical-align);
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  animation: var(--#{$prefix}spinner-animation-speed) linear infinite var(--#{$prefix}spinner-animation-name);
}

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}
// scss-docs-end spinner-border-keyframes

.spinner-border {
  // scss-docs-start spinner-border-css-vars
  --#{$prefix}spinner-width: #{$spinner-width};
  --#{$prefix}spinner-height: #{$spinner-height};
  --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
  --#{$prefix}spinner-border-width: #{$spinner-border-width};
  --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
  --#{$prefix}spinner-animation-name: spinner-border;
  // scss-docs-end spinner-border-css-vars

  border: var(--#{$prefix}spinner-border-width) solid currentcolor;
  border-right-color: transparent;
  forced-color-adjust: preserve-parent-color;
}

.spinner-border-sm {
  // scss-docs-start spinner-border-sm-css-vars
  --#{$prefix}spinner-width: #{$spinner-width-sm};
  --#{$prefix}spinner-height: #{$spinner-height-sm};
  --#{$prefix}spinner-border-width: #{$spinner-border-width-sm};
  // scss-docs-end spinner-border-sm-css-vars
}

.spinner-border-lg {
  // scss-docs-start spinner-border-lg-css-vars
  --#{$prefix}spinner-width: #{$spinner-width-lg};
  --#{$prefix}spinner-height: #{$spinner-height-lg};
  --#{$prefix}spinner-border-width: #{$spinner-border-width-lg};
  // scss-docs-end spinner-border-lg-css-vars
}

@if $enable-reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    .spinner-border {
      --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed * 2};
    }
  }
}

@media (forced-colors: active) {
  .spinner-grow {
    background-color: ButtonText;
    border: 1px solid transparent;
  }
}
