@mixin bsBanner($file) {
  /*!
  * Modus Bootstrap #{$file} v2.0.9 (https://modus-bootstrap.trimble.com/)
   * Copyright 2025 Trimble Inc.
   * Licensed under MIT (https://github.com/trimble-oss/modus-bootstrap/blob/main/LICENSE)
   * This a fork of Bootstrap: Initial license below
   * Bootstrap v5.3.3 (https://getbootstrap.com)
   * Copyright 2011-2025 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
}

